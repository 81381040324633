import React, {useEffect, useState, useRef} from 'react'
import {KTCardBody, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getAllBanner, deleteBanner, addBanner} from '../../../../API/api-endpoint'
import ToastUtils, {ErrorToastUtils} from '../../../../utils/ToastUtils'
import clsx from 'clsx'
import Swal from 'sweetalert2'
import LightBoxComponent from '../../../../_metronic/partials/componants/LightBoxComponent'

const StandardBanner = () => {
  const hiddenFileInput = useRef<HTMLInputElement>(document.createElement('input'))

  const [openLightBox, setOpenLightBox] = useState(false)
  const [lightBoxArrayList, setLightBoxArrayList] = useState<any>([])
  const [bannerList, setBannerList] = useState<any>([])
  const [tempPath, setTempPath] = useState<any>('')
  const [file, setFile] = useState('')
  const [formData, setFormData] = useState<any>({
    image: '',
    url: '',
  })

  useEffect(() => {
    getBannerList()
  }, [])

  const createBanner = async () => {
    let result = await addBanner('standard', file, formData.url)
    if (result.status == true) {
      getBannerList()
      setFormData({
        image: '',
        url: '',
      })
      setFile('')
      setTempPath('')
    }
  }

  const getBannerList = async () => {
    let result = await getAllBanner('standard')
    if (result.status === true) {
      setBannerList(result.data)
    }
  }

  const deleteBannerById = async (bannerId: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let result = await deleteBanner('standard', bannerId)
        if (result.status === true) {
          ToastUtils({type: 'success', message: result.message})
          getBannerList()
        } else {
          ToastUtils({type: 'error', message: result.message})
        }
      }
    })
  }

  const handleClick = () => {
    const fileInput = document.getElementById(`fileInput`)
    fileInput?.click()
  }

  const handleIconChange = (event: any) => {
    const fileUploaded = event.target.files[0]
    var tmpPath = URL.createObjectURL(event.target.files[0])
    setTempPath(tmpPath)
    setFile(fileUploaded)
  }

  const handleaddMediaforLightbox = (url: string) => {
    let PhotoObject = {
      src: url,
    }
    setLightBoxArrayList([PhotoObject])
  }

  return (
    <>
      <div className='d-flex justify-content-between mb-2'>
        <h1>Banner List</h1>
        <button
          className='btn btn-primary '
          data-bs-toggle='modal'
          data-bs-target='#add_movie_model'
        >
          Add Banner
        </button>
      </div>
      <KTCardBody className='py-4 card'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <td style={{maxWidth: '250px'}}>Banner</td>
                <td>Url</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody className='text-gray-600 '>
              {bannerList.length !== 0 &&
                bannerList.map((banner: any, index: any) => {
                  return (
                    <>
                      <tr key={index}>
                        <td style={{maxWidth: '250px'}}>
                          <div
                            className='symbol symbol-50px overflow-visible me-3'
                            onClick={() => {
                              handleaddMediaforLightbox(
                                `${process.env.REACT_APP_SERVER_URL}/${banner?.image}`
                              )
                              setOpenLightBox(true)
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_SERVER_URL}/${banner?.image}`}
                              width='50px'
                              height='50px'
                              loading='lazy'
                              alt='logo'
                            />
                          </div>
                        </td>
                        <td style={{maxWidth: '100px'}}>
                          <a href={banner?.url} target='_blank' rel='noreferrer'>
                            {banner?.url}
                          </a>
                        </td>
                        <td>
                          <button
                            className='btn btn-danger small'
                            onClick={() => deleteBannerById(banner.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </>
                  )
                })}
            </tbody>
          </table>
        </div>

        <div className='modal fade' tabIndex={-1} id='add_movie_model'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Add Banner</h5>
              </div>
              <div className='modal-body'>
                <div className='d-flex align-items-center '>
                  <div className='flex-grow-1'>
                    <img
                      src={file.length !== 0 ? `${tempPath}` : toAbsoluteUrl('/media/auth/add.png')}
                      alt='icon'
                      loading='lazy'
                      height={100}
                      width={100}
                      onClick={() => {
                        handleClick()
                      }}
                    />

                    <input
                      type='file'
                      name='icon'
                      id={`fileInput`}
                      onChange={(e) => handleIconChange(e)}
                      ref={hiddenFileInput}
                      style={{display: 'none'}} // Make the file input element invisible
                      accept='image/*'
                    />
                    <input
                      placeholder='Enter Url'
                      type='text'
                      name='url'
                      className={clsx('form-control form-control-solid mt-3')}
                      autoComplete='off'
                      value={formData.url}
                      onChange={(e) => setFormData({...formData, url: e.target.value})}
                    />
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    setFormData({
                      image: '',
                      url: '',
                    })
                  }}
                >
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    createBanner()
                  }}
                >
                  Add Movie
                </button>
              </div>
            </div>
          </div>
        </div>
      </KTCardBody>
      <LightBoxComponent
        openLightBox={openLightBox}
        setOpenLightBox={setOpenLightBox}
        lightBoxArray={lightBoxArrayList}
        imageIndex={0}
      />
    </>
  )
}

export default StandardBanner
