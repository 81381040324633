import axios from 'axios'

let APIURL = process.env.REACT_APP_SERVER_URL

export const loginWithEmailandPassword = async (email, password) => {
  let formdata = new FormData()
  formdata = {
    email: email,
    password: password,
  }

  const apiUrl = `${APIURL}/adminPanel/login/admin`

  let response = {
    data: {
      status: 200,
      data: {
        token: 'fe',
        admin_user_id: 1,
      },
    },
  }

  if (response.data.status == 200) {
    localStorage.setItem('accessToken', response.data.data.token)
    sessionStorage.setItem('accessToken', response.data.data.token)
    localStorage.setItem('loginUserId', response.data.data.admin_user_id)
  }

  return response.data
}

// movie list

export const getAllMovie = async (page, pageSize, search, from_date, to_date, category) => {
  try {
    let apiUrl = `${APIURL}/api/get-all-items.php?page=${page}&pageSize=${pageSize}`

    if (search.trim().length !== 0) {
      apiUrl += `&search=${search}`
    }

    if (from_date.trim().length !== 0) {
      apiUrl += `&from_date=${from_date}`
    }

    if (to_date.trim().length !== 0) {
      apiUrl += `&to_date=${to_date}`
    }

    if (category.trim().length !== 0) {
      apiUrl += `&category=${category}`
    }

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const addMovie = async (data, rows) => {
  try {
    let formData = new FormData()
    formData = {
      name: data.name,
      category: data.category,
      url1: data.url1,
      url2: data.url2,
      url3: data.url3,
      url4: data.url4,
      isFolder: data.isFolder === true ? 1 : 0,
    }

    if (data.isFolder === true) {
      formData = {
        ...formData,
        episode: JSON.stringify(rows),
      }
    }

    const apiUrl = `${APIURL}/api/create.php`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const deleteMovie = async (movieId) => {
  try {
    const apiUrl = `${APIURL}/api/delete-item.php?id=${movieId}`

    let response = await axios.delete(apiUrl)

    return response.data
  } catch (error) {
    return error.message
  }
}

export const updateMovie = async (data, rows) => {
  try {
    let formData = new FormData()
    formData = {
      name: data.name,
      category: data.category,
      url1: data.url1,
      url2: data.url2,
      url3: data.url3,
      url4: data.url4,
      isFolder: data.isFolder,
    }

    if (data.isFolder === '1') {
      formData = {
        ...formData,
        episode: JSON.stringify(rows),
      }
    }

    const apiUrl = `${APIURL}/api/update-item.php?id=${data?.id}`

    let response = await axios.put(apiUrl, formData, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// banner

export const getAllBanner = async (type) => {
  try {
    let apiUrl = `${APIURL}/api/banner.php`

    if (type === 'standard') {
      apiUrl += `?type=standard`
    }

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const addBanner = async (type, file, url) => {
  try {
    let formData = new FormData()
    formData = {
      file: file,
      url: url,
    }

    if (type === 'standard') {
      formData = {
        file: file,
        url: url,
        type: 'standard',
      }
    }

    let apiUrl = `${APIURL}/api/banner.php`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const deleteBanner = async (type, bannerId) => {
  try {
    let apiUrl = `${APIURL}/api/banner.php?id=${bannerId}`

    if (type === 'standard') {
      apiUrl += `&type=standard`
    }

    let response = await axios.delete(apiUrl)

    return response.data
  } catch (error) {
    return error.message
  }
}

// suggestions

export const getAllSuggestions = async () => {
  try {
    const apiUrl = `${APIURL}/api/suggestion.php`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const addSuggestion = async (suggestion) => {
  try {
    let formData = new FormData()
    formData = {
      name: suggestion,
    }

    const apiUrl = `${APIURL}/api/suggestion.php`

    let response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const deleteSuggestion = async (suggestionId) => {
  try {
    const apiUrl = `${APIURL}/api/suggestion.php?id=${suggestionId}`

    let response = await axios.delete(apiUrl)

    return response.data
  } catch (error) {
    return error.message
  }
}

// policy

export const getAllPolicy = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/policies/getAllPolicy`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const UpdatePolicy = async (policyId, html, title) => {
  try {
    let data = {
      policyId: policyId,
      html: html,
      title: title,
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/policies/updatePolicy`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

// ads setting

export const getAllAds = async () => {
  try {
    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/ads/getAllAdsforAdmin`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const UpdateAds = async (adsId, adskey) => {
  try {
    let data = {
      ads: {
        ads_key: adskey,
      },
    }

    let accessToken = localStorage.getItem('accessToken')
    const apiUrl = `${APIURL}/ads/updateAds/${adsId}`

    let response = await axios.post(apiUrl, data, {
      headers: {
        'Content-Type': `application/json`,
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

//config

export const getConfigurations = async () => {
  try {
    const apiUrl = `${APIURL}/api/config.php`

    let response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}

export const updateConfigurationById = async (configId, values) => {
  try {
    let data = {
      value: values,
    }

    const apiUrl = `${APIURL}/api/config.php?id=${configId}`

    let response = await axios.put(apiUrl, data, {
      headers: {
        'Content-Type': `application/x-www-form-urlencoded`,
      },
    })

    return response.data
  } catch (error) {
    console.log(error.message)

    return error.message
  }
}
