import React, {useEffect, useState} from 'react'
import ToastUtils, {ErrorToastUtils} from '../../../../../../../utils/ToastUtils'
import {getConfigurations, updateConfigurationById} from '../../../../../../../API/api-endpoint'

const GeneralPlugin = () => {
  const [config, setConfig] = useState({
    isAdEnable: '0',
    openApiKey: '',
    appIdAd: '',
    appOpenAd: '',
    nativeAd: '',
    interstitialAd: '',
    rewardedAd: '',
  })

  useEffect(() => {
    getAllConfigurations()
  }, [])

  const getAllConfigurations = async () => {
    let result = await getConfigurations()
    if (result.status == true) {
      setConfig({
        isAdEnable: result.data[0].value,
        openApiKey: result.data[1].value,
        appIdAd: result.data[2].value,
        appOpenAd: result.data[3].value,
        nativeAd: result.data[4].value,
        interstitialAd: result.data[5].value,
        rewardedAd: result.data[6].value,
      })
    }
  }

  const handleChange = (value: any, name: any, configId: any) => {
    if (name === 'isAdEnable') {
      value = value == true ? '1' : '0'
      updateConfiguration(value, configId)
    }
  }

  const onBlurUpdate = (value: any, configId: any) => {
    updateConfiguration(value, configId)
  }

  const updateConfiguration = async (config: any, configID: any) => {
    let result = await updateConfigurationById(configID, config)
    if (result.status == true) {
      getAllConfigurations()
      ToastUtils({type: 'success', message: 'Configuration Saved SuccessFully'})
    } else {
      ErrorToastUtils()
    }
  }

  return (
    <div className='card'>
      <div className='card-title p-8'>
        <h2>General settings</h2>
      </div>
      <div className='card-body'>
        <div className='row no-gutters'>
          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Enable Ads</strong>
            </p>
            <p className='text-muted'>Enable or disable Ads</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                name='isAdEnable'
                checked={config.isAdEnable == '0' ? false : true}
                onChange={(event) => handleChange(event.target.checked, 'isAdEnable', 1)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Open AI Key</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <input
                type='text'
                className='form-control'
                name='openApiKey'
                value={config.openApiKey}
                onChange={(event) => setConfig({...config, openApiKey: event.target.value})}
                onBlur={(event) => onBlurUpdate(event.target.value, 2)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>App Id</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <input
                type='text'
                className='form-control'
                name='AppIdAd'
                value={config.appIdAd}
                onChange={(event) => setConfig({...config, appIdAd: event.target.value})}
                onBlur={(event) => onBlurUpdate(event.target.value, 3)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>App Open Ad Id</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <input
                type='text'
                className='form-control'
                name='NativeAd'
                value={config.appOpenAd}
                onChange={(event) => setConfig({...config, appOpenAd: event.target.value})}
                onBlur={(event) => onBlurUpdate(event.target.value, 4)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Native Ad Id</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <input
                type='text'
                className='form-control'
                name='InterstitialAd'
                value={config.nativeAd}
                onChange={(event) => setConfig({...config, nativeAd: event.target.value})}
                onBlur={(event) => onBlurUpdate(event.target.value, 5)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Interstitial Ad Id</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <input
                type='text'
                className='form-control'
                name='InterstitialAd'
                value={config.interstitialAd}
                onChange={(event) => setConfig({...config, interstitialAd: event.target.value})}
                onBlur={(event) => onBlurUpdate(event.target.value, 6)}
              />
            </div>
          </div>

          <div className='col-lg-4 card-body bg-light'>
            <p>
              <strong className='headings-color'>Rewarded Ad Id</strong>
            </p>
            <p className='text-muted'>-</p>
          </div>
          <div className='col-lg-8 card-form__body card-body d-flex align-items-center bg-white'>
            <div className='flex'>
              <input
                type='text'
                className='form-control'
                name='RewardedAd'
                value={config.rewardedAd}
                onChange={(event) => setConfig({...config, rewardedAd: event.target.value})}
                onBlur={(event) => onBlurUpdate(event.target.value, 7)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralPlugin
