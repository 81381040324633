import React, {useEffect, useState} from 'react'
import {KTCardBody} from '../../../../_metronic/helpers'
import CustomPagination from '../../../../_metronic/partials/componants/Pagination'
import {getAllMovie, updateMovie, deleteMovie, addMovie} from '../../../../API/api-endpoint'
import ToastUtils, {ErrorToastUtils} from '../../../../utils/ToastUtils'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import Swal from 'sweetalert2'
import {DateWithTimeFormatter} from '../../../../utils/DateUtils'
import {Dropdown} from 'react-bootstrap'
import {CustomToggle} from '../../../../_metronic/partials/componants/CustomToggle'

const MovieManagement = () => {
  const [movieList, setMovieList] = useState<any>([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [idCounter, setIdCounter] = useState(2)
  const [formData, setFormData] = useState<any>({
    name: '',
    url1: '',
    url2: '',
    url3: '',
    url4: '',
    category: '',
    isFolder: '',
  })
  const [rows, setRows] = useState([{id: 0, name: '', url1: '', url2: '', url3: '', url4: ''}])
  const [selectedMovie, setSelectedMovie] = useState<any>([])
  const [filter, setFilter] = useState({search: '', from_date: '', to_date: '', category: ''})

  const handleInputChange = (index: any, field: any, value: any) => {
    const updatedRows = [...rows]
    updatedRows[index][field] = value
    setRows(updatedRows)
  }

  const handleAddRow = () => {
    setRows([...rows, {id: idCounter, name: '', url1: '', url2: '', url3: '', url4: ''}])
    setIdCounter(idCounter + 1)
  }

  const handleRemoveRow = (index: any) => {
    const updatedRows = rows.filter((row) => row.id !== index)
    setRows(updatedRows)
  }

  useEffect(() => {
    getMovieList(1, pageSize, '', '', '', '')
  }, [])

  const createMovie = async () => {
    let result = await addMovie(formData, rows)
    if (result.status == true) {
      setFormData({
        name: '',
        url1: '',
        url2: '',
        url3: '',
        url4: '',
        category: '',
        isFolder: '',
      })
      getMovieList(1, pageSize, '', '', '', '')
    }
  }

  const getPagination = (page: any, pageSize: any) => {
    if (page === 0 || page === 1) {
      page = 1
    }
    setPage(page)
    getMovieList(page, pageSize, filter.search, filter.from_date, filter.to_date, filter.category)
  }

  const getMovieList = async (
    page: any,
    pageSize: any,
    search: any,
    from_date: any,
    to_date: any,
    category: any
  ) => {
    let result = await getAllMovie(page, pageSize, search, from_date, to_date, category)
    if (result.status === true) {
      setMovieList(result.data)
      setTotalPage(result.totalPage)
      setTotalCount(result.totalCount)
    }
  }

  const deleteMovies = async (movieId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let result = await deleteMovie(movieId)
        if (result.status === true) {
          ToastUtils({type: 'success', message: result.message})
          getMovieList(page, pageSize, '', '', '', '')
        } else {
          ToastUtils({type: 'error', message: result.message})
        }
      }
    })
  }

  const updateMovieItem = async () => {
    let result = await updateMovie(selectedMovie, rows)

    if (result.status === true) {
      getMovieList(page, pageSize, '', '', '', '')
      ToastUtils({
        type: 'success',
        message: 'Movie Updated SuccessFully',
      })
      setFormData({
        name: '',
        url1: '',
        url2: '',
        url3: '',
        url4: '',
        category: '',
        isFolder: '',
      })
    } else {
      ErrorToastUtils()
    }
  }

  const clearFilter = () => {
    setFilter({search: '', from_date: '', to_date: '', category: ''})
    getMovieList(page, pageSize, '', '', '', '')
  }

  const filterMedia = () => {
    if (
      filter.search.trim().length !== 0 ||
      filter.from_date.trim().length !== 0 ||
      filter.to_date.trim().length !== 0 ||
      filter.category.trim().length !== 0
    ) {
      getMovieList(page, pageSize, filter.search, filter.from_date, filter.to_date, filter.category)
    }
  }

  return (
    <>
      <div className='d-flex justify-content-between mb-2'>
        <h1>Movie List</h1>
        <button
          className='btn btn-primary '
          data-bs-toggle='modal'
          data-bs-target='#add_movie_model'
        >
          Add Movie
        </button>
      </div>

      <div className='card py-4 px-4 mb-5'>
        <div className='d-flex justify-content-between'>
          <div className='row'>
            <div className='col-3'>
              <label className='form-label fs-6 text-muted'>Search</label>
              <input
                placeholder='Search By Movie Name'
                type='text'
                name='search'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
                value={filter.search}
                onChange={(e) => setFilter({...filter, search: e.target.value})}
              />
            </div>
            <div className='col-3'>
              <label className='form-label fs-6 text-muted'>Start Date</label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                placeholder='Select From Date'
                name='from_date'
                value={filter.from_date}
                onChange={(e) => setFilter({...filter, from_date: e.target.value})}
              />
            </div>
            <div className='col-3'>
              <label className='form-label fs-6 text-muted'>End Date</label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                placeholder='Select To Date'
                name='to_date'
                value={filter.to_date}
                onChange={(e) => setFilter({...filter, to_date: e.target.value})}
              />
            </div>
            <div className='col-3'>
              <label className='form-label fs-6 text-muted'>Category</label>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-hide-search='true'
                name='category'
                value={filter.category}
                onChange={(e) => setFilter({...filter, category: e.target.value})}
              >
                <option value=''>Select Category</option>
                <option value='bollywood'>Bollywood</option>
                <option value='hollywood'>Hollywood</option>
                <option value='tollywood'>Tollywood</option>
                <option value='webseries_holly'>HollyWood Web series</option>
                <option value='webseries_bolly'>BollyWood Web series</option>
                <option value='gujarati'>Gujarati</option>
                <option value='anime'>Anime</option>
                <option value='kdrama'>K-Drama</option>
                <option value='eighteenplus'>18 +</option>
              </select>
            </div>
          </div>
          <div>
            <button type='submit' className={'btn btn-primary me-3'} onClick={filterMedia}>
              Apply
            </button>
            <button type='submit' className={'btn btn-warning'} onClick={clearFilter}>
              Clear
            </button>
          </div>
        </div>
      </div>

      <div className='card py-4 px-4 mb-5'>
        <div className='d-flex justify-content-between'>
          <h4 className='mt-2'>SEARCH RESULT {totalCount} MOVIES </h4>
        </div>
      </div>

      <KTCardBody className='py-4 card'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-bordered'
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <td style={{maxWidth: '250px'}}>Name</td>
                <td>Category</td>
                <td style={{maxWidth: '100px'}}>Url 1</td>
                <td style={{maxWidth: '100px'}}>Url 2</td>
                <td style={{maxWidth: '100px'}}>Url 3</td>
                <td style={{maxWidth: '100px'}}>Url 4</td>
                <td>Created At</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody className='text-gray-600 '>
              {movieList.length !== 0 &&
                movieList.map((movie: any, index: any) => {
                  return (
                    <>
                      <tr key={index}>
                        <td style={{maxWidth: '250px'}}>
                          <span>
                            {movie?.name}
                            {movie?.isFolder === '1' && (
                              <span
                                className='btn btn-link'
                                data-bs-toggle='modal'
                                data-bs-target='#episode_model'
                                onClick={() => setSelectedMovie(movie)}
                              >
                                <i className='bi bi-folder-fill ms-4'></i>
                              </span>
                            )}
                          </span>
                        </td>
                        <td>
                          <span>{movie?.category}</span>
                        </td>
                        <td style={{maxWidth: '100px'}}>
                          <a href={movie?.url1} target='_blank' rel='noreferrer'>
                            {movie?.url1}
                          </a>
                        </td>
                        <td style={{maxWidth: '100px'}}>
                          <a href={movie?.url2} target='_blank' rel='noreferrer'>
                            {movie?.url2}
                          </a>
                        </td>
                        <td style={{maxWidth: '100px'}}>
                          <a href={movie?.url3} target='_blank' rel='noreferrer'>
                            {movie?.url3}
                          </a>
                        </td>
                        <td style={{maxWidth: '100px'}}>
                          <a href={movie?.url4} target='_blank' rel='noreferrer'>
                            {movie?.url4}
                          </a>
                        </td>
                        <td>
                          <span>{DateWithTimeFormatter(movie?.createdAt)}</span>
                        </td>
                        <td>
                          <div className='d-flex my-4'>
                            <Dropdown>
                              <Dropdown.Toggle
                                as={CustomToggle}
                                id='dropdown-basic'
                                className='bg-body-secondary bg-body-secondary:hover'
                                size='sm'
                              ></Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    setSelectedMovie(movie)
                                    if (movie.isFolder === '1') {
                                      setRows(movie.episodeData)
                                    }
                                  }}
                                  data-bs-toggle='modal'
                                  data-bs-target='#update_movie_model'
                                >
                                  Edit Movie
                                </Dropdown.Item>

                                <Dropdown.Item onClick={() => deleteMovies(movie?.id)}>
                                  Delete Movie
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    </>
                  )
                })}
            </tbody>
          </table>
        </div>
        <div className='card-footer'>
          {movieList.length !== 0 && (
            <CustomPagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              totalPage={totalPage}
              cb={getPagination}
            />
          )}
        </div>

        <div className='modal fade' tabIndex={-1} id='add_movie_model'>
          <div className='modal-dialog modal-xl'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Add Movie</h5>
              </div>
              <div className='modal-body'>
                <div className='d-flex align-items-center '>
                  <div className='flex-grow-1'>
                    <input
                      placeholder='Enter Name'
                      type='text'
                      name='name'
                      className={clsx('form-control form-control-solid')}
                      autoComplete='off'
                      value={formData.name}
                      onChange={(e) => setFormData({...formData, name: e.target.value})}
                    />
                    <input
                      placeholder='Enter Url 1'
                      type='text'
                      name='url1'
                      className={clsx('form-control form-control-solid mt-3')}
                      autoComplete='off'
                      value={formData.url1}
                      onChange={(e) => setFormData({...formData, url1: e.target.value})}
                    />
                    <input
                      placeholder='Enter Url 2'
                      type='text'
                      name='url2'
                      className={clsx('form-control form-control-solid mt-3')}
                      autoComplete='off'
                      value={formData.url2}
                      onChange={(e) => setFormData({...formData, url2: e.target.value})}
                    />
                    <input
                      placeholder='Enter Url 3'
                      type='text'
                      name='url3'
                      className={clsx('form-control form-control-solid mt-3')}
                      autoComplete='off'
                      value={formData.url3}
                      onChange={(e) => setFormData({...formData, url3: e.target.value})}
                    />
                    <input
                      placeholder='Enter Url 4'
                      type='text'
                      name='url4'
                      className={clsx('form-control form-control-solid mt-3')}
                      autoComplete='off'
                      value={formData.url4}
                      onChange={(e) => setFormData({...formData, url4: e.target.value})}
                    />

                    <select
                      className='form-select form-select-solid fw-bolder mt-3'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      data-hide-search='true'
                      name='category'
                      value={formData.category}
                      onChange={(e) => setFormData({...formData, category: e.target.value})}
                    >
                      <option value=''>Select Category</option>
                      <option value='bollywood'>Bollywood</option>
                      <option value='hollywood'>Hollywood</option>
                      <option value='tollywood'>Tollywood</option>
                      <option value='webseries_holly'>HollyWood Web series</option>
                      <option value='webseries_bolly'>BollyWood Web series</option>
                      <option value='gujarati'>Gujarati</option>
                      <option value='anime'>Anime</option>
                      <option value='kdrama'>K-Drama</option>
                      <option value='eighteenplus'>18 +</option>
                    </select>
                    <div className='d-flex'>
                      {/* <label className='form-check form-switch form-check-custom form-check-solid'></label> */}
                      <input
                        className='form-check-input mt-3'
                        type='checkbox'
                        checked={formData.isFolder}
                        onChange={(e) => setFormData({...formData, isFolder: e.target.checked})}
                        name='isFolder'
                      />
                      <span className='form-check-label text-muted fs-6 mt-3 ms-2'>Is Folder</span>
                    </div>

                    {formData.isFolder && (
                      <div
                        className='mt-4 table-responsive'
                        style={{maxHeight: 200, overflowY: 'scroll'}}
                      >
                        <table>
                          <thead>
                            <tr>
                              <th>Ep. Name</th>&nbsp;
                              <th>Ep. Url 1 </th>
                              <th>Ep. Url 2</th>
                              <th>Ep. Url 3</th>
                              <th>Ep. Url 4</th>&nbsp;
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rows.map((row, index) => (
                              <tr key={row.id}>
                                <td>
                                  <input
                                    type='text'
                                    className={clsx('form-control form-control-solid')}
                                    value={row.name}
                                    onChange={(e) =>
                                      handleInputChange(index, 'name', e.target.value)
                                    }
                                  />
                                </td>
                                &nbsp;
                                <td>
                                  <input
                                    type='text'
                                    className={clsx('form-control form-control-solid ps-2')}
                                    value={row.url1}
                                    onChange={(e) =>
                                      handleInputChange(index, 'url1', e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type='text'
                                    className={clsx('form-control form-control-solid ps-2')}
                                    value={row.url2}
                                    onChange={(e) =>
                                      handleInputChange(index, 'url2', e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type='text'
                                    className={clsx('form-control form-control-solid ps-2')}
                                    value={row.url3}
                                    onChange={(e) =>
                                      handleInputChange(index, 'url3', e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type='text'
                                    className={clsx('form-control form-control-solid ps-2')}
                                    value={row.url4}
                                    onChange={(e) =>
                                      handleInputChange(index, 'url4', e.target.value)
                                    }
                                  />
                                </td>
                                &nbsp;
                                <td>
                                  <div
                                    className='btn btn-danger small'
                                    onClick={() => handleRemoveRow(row.id)}
                                  >
                                    <i className='bi bi-trash'></i>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <button className='btn btn-primary small mt-4' onClick={handleAddRow}>
                          Add Row
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    setFormData({
                      name: '',
                      url1: '',
                      url2: '',
                      url3: '',
                      url4: '',
                      category: '',
                      isFolder: '',
                    })
                    setRows([{id: 0, name: '', url1: '', url2: '', url3: '', url4: ''}])
                  }}
                >
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    createMovie()
                  }}
                >
                  Add Movie
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='modal fade' tabIndex={-1} id='update_movie_model'>
          <div className='modal-dialog modal-xl'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Update Movie</h5>
              </div>
              <div className='modal-body'>
                <div className='d-flex align-items-center '>
                  <div className='flex-grow-1'>
                    <input
                      placeholder='Enter Name'
                      type='text'
                      name='name'
                      className={clsx('form-control form-control-solid')}
                      autoComplete='off'
                      value={selectedMovie.name}
                      onChange={(e) => setSelectedMovie({...selectedMovie, name: e.target.value})}
                    />
                    <input
                      placeholder='Enter Url 1'
                      type='text'
                      name='url1'
                      className={clsx('form-control form-control-solid mt-3')}
                      autoComplete='off'
                      value={selectedMovie.url1}
                      onChange={(e) => setSelectedMovie({...selectedMovie, url1: e.target.value})}
                    />
                    <input
                      placeholder='Enter Url 2'
                      type='text'
                      name='url2'
                      className={clsx('form-control form-control-solid mt-3')}
                      autoComplete='off'
                      value={selectedMovie.url2}
                      onChange={(e) => setSelectedMovie({...selectedMovie, url2: e.target.value})}
                    />
                    <input
                      placeholder='Enter Url 3'
                      type='text'
                      name='url3'
                      className={clsx('form-control form-control-solid mt-3')}
                      autoComplete='off'
                      value={selectedMovie.url3}
                      onChange={(e) => setSelectedMovie({...selectedMovie, url3: e.target.value})}
                    />
                    <input
                      placeholder='Enter Url 4'
                      type='text'
                      name='url4'
                      className={clsx('form-control form-control-solid mt-3')}
                      autoComplete='off'
                      value={selectedMovie.url4}
                      onChange={(e) => setSelectedMovie({...selectedMovie, url4: e.target.value})}
                    />
                    <select
                      className='form-select form-select-solid fw-bolder mt-3'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      data-hide-search='true'
                      name='category'
                      value={selectedMovie.category}
                      onChange={(e) =>
                        setSelectedMovie({...selectedMovie, category: e.target.value})
                      }
                    >
                      <option value=''>Select Category</option>
                      <option value='bollywood'>Bollywood</option>
                      <option value='hollywood'>Hollywood</option>
                      <option value='tollywood'>Tollywood</option>
                      <option value='webseries_holly'>HollyWood Web series</option>
                      <option value='webseries_bolly'>BollyWood Web series</option>
                      <option value='gujarati'>Gujarati</option>
                      <option value='anime'>Anime</option>
                      <option value='kdrama'>K-Drama</option>
                      <option value='eighteenplus'>18 +</option>
                    </select>
                    <label className='form-check form-switch form-check-custom form-check-solid'>
                      <input
                        className='form-check-input w-30px h-20px mt-3'
                        type='checkbox'
                        checked={selectedMovie.isFolder === '0' ? false : true}
                        onChange={(e) =>
                          setSelectedMovie({
                            ...selectedMovie,
                            isFolder: e.target.checked == true ? '1' : '0',
                          })
                        }
                        name='isFolder'
                      />
                      <span className='form-check-label text-muted fs-6'>Is Folder</span>
                    </label>
                    {selectedMovie.isFolder === '1' && (
                      <div
                        className='mt-4 table-responsive'
                        style={{maxHeight: 200, overflowY: 'scroll'}}
                      >
                        <table>
                          <thead>
                            <tr>
                              <th>Ep. Name</th>&nbsp;
                              <th>Ep. Url 1</th>
                              <th>Ep. Url 2</th>
                              <th>Ep. Url 3</th>
                              <th>Ep. Url 4</th>&nbsp;
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rows.map((row, index) => (
                              <tr key={row.id}>
                                <td>
                                  <input
                                    type='text'
                                    className={clsx('form-control form-control-solid ps-2')}
                                    value={row.name}
                                    onChange={(e) =>
                                      handleInputChange(index, 'name', e.target.value)
                                    }
                                  />
                                </td>
                                &nbsp;
                                <td>
                                  <input
                                    type='text'
                                    className={clsx('form-control form-control-solid ps-2')}
                                    value={row.url1}
                                    onChange={(e) =>
                                      handleInputChange(index, 'url1', e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type='text'
                                    className={clsx('form-control form-control-solid ps-2')}
                                    value={row.url2}
                                    onChange={(e) =>
                                      handleInputChange(index, 'url2', e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type='text'
                                    className={clsx('form-control form-control-solid ps-2')}
                                    value={row.url3}
                                    onChange={(e) =>
                                      handleInputChange(index, 'url3', e.target.value)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type='text'
                                    className={clsx('form-control form-control-solid ps-2')}
                                    value={row.url4}
                                    onChange={(e) =>
                                      handleInputChange(index, 'url4', e.target.value)
                                    }
                                  />
                                </td>
                                &nbsp;
                                <td>
                                  <div
                                    className='btn btn-danger small'
                                    onClick={() => handleRemoveRow(row.id)}
                                  >
                                    <i className='bi bi-trash'></i>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <button className='btn btn-primary small' onClick={handleAddRow}>
                          Add Row
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    setSelectedMovie({})
                    setRows([{id: 0, name: '', url1: '', url2: '', url3: '', url4: ''}])
                  }}
                >
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    updateMovieItem()
                  }}
                >
                  Update Movie
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='modal fade' tabIndex={-1} id='episode_model'>
          <div className='modal-dialog modal-xl'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>{selectedMovie.name} - Episode List</h5>
              </div>
              <div className='modal-body table-responsive'>
                <table id='kt_table_users' className='table fs-6 gy-5  no-footer'>
                  <div style={{maxHeight: '410px', overflowY: 'scroll'}}>
                    <thead>
                      <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        <td style={{maxWidth: '200px'}}>Ep. Name</td>
                        <td style={{maxWidth: '250px'}}>Ep. Url 1</td>
                        <td style={{maxWidth: '250px'}}>Ep. Url 2</td>
                        <td style={{maxWidth: '250px'}}>Ep. Url 3</td>
                        <td style={{maxWidth: '250px'}}>Ep. Url 4</td>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600'>
                      {selectedMovie.length !== 0 &&
                        selectedMovie.episodeData !== undefined &&
                        selectedMovie.episodeData.map((episode: any, index: any) => {
                          return (
                            <>
                              <tr key={index}>
                                <td style={{maxWidth: '250px'}}>
                                  <span>{episode?.name}</span>
                                </td>
                                <td style={{maxWidth: '250px'}}>
                                  <a href={episode?.url1} target='_blank' rel='noreferrer'>
                                    {episode?.url1}
                                  </a>
                                </td>
                                <td style={{maxWidth: '250px'}}>
                                  <a href={episode?.url2} target='_blank' rel='noreferrer'>
                                    {episode?.url2}
                                  </a>
                                </td>
                                <td style={{maxWidth: '250px'}}>
                                  <a href={episode?.url3} target='_blank' rel='noreferrer'>
                                    {episode?.url3}
                                  </a>
                                </td>
                                <td style={{maxWidth: '250px'}}>
                                  <a href={episode?.url4} target='_blank' rel='noreferrer'>
                                    {episode?.url4}
                                  </a>
                                </td>
                              </tr>
                            </>
                          )
                        })}{' '}
                    </tbody>
                  </div>
                </table>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    setSelectedMovie([])
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </KTCardBody>
    </>
  )
}

export default MovieManagement
