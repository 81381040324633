/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='admin/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='admin/movie-management'
        icon='abstract-28'
        title='Movie List'
        fontIcon='bi-person'
      />

      <SidebarMenuItem
        to='admin/standard-banner'
        icon='abstract-28'
        title='Standard Banner'
        fontIcon='bi-person'
      />

      <SidebarMenuItem
        to='admin/banner'
        icon='abstract-28'
        title='Pro Banner'
        fontIcon='bi-person'
      />

      <SidebarMenuItem
        to='admin/suggestion'
        icon='abstract-28'
        title='Suggestions'
        fontIcon='bi-person'
      />

      <SidebarMenuItem to='admin/plugin' icon='abstract-28' title='Plugins' fontIcon='bi-person' />
    </>
  )
}

export {SidebarMenuMain}
