import React, {useEffect, useState} from 'react'
import {KTCardBody} from '../../../../_metronic/helpers'
import {getAllSuggestions, deleteSuggestion, addSuggestion} from '../../../../API/api-endpoint'
import ToastUtils from '../../../../utils/ToastUtils'
import clsx from 'clsx'
import Swal from 'sweetalert2'

const Suggestions = () => {
  const [suggestionList, setSuggestionList] = useState<any>([])
  const [formData, setFormData] = useState<any>({
    name: '',
  })

  useEffect(() => {
    getSuggestionList()
  }, [])

  const createSuggestion = async () => {
    let result = await addSuggestion(formData.name)
    if (result.status == true) {
      getSuggestionList()
      setFormData({
        name: '',
      })
    }
  }

  const getSuggestionList = async () => {
    let result = await getAllSuggestions()
    if (result.status === true) {
      setSuggestionList(result.data)
    }
  }

  const deleteSuggestionById = async (suggestionsId: any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let result = await deleteSuggestion(suggestionsId)
        if (result.status === true) {
          ToastUtils({type: 'success', message: result.message})
          getSuggestionList()
        } else {
          ToastUtils({type: 'error', message: result.message})
        }
      }
    })
  }

  return (
    <>
      <div className='d-flex justify-content-between mb-2'>
        <h1>Banner List</h1>
        <button
          className='btn btn-primary '
          data-bs-toggle='modal'
          data-bs-target='#add_movie_model'
        >
          Add Banner
        </button>
      </div>
      <KTCardBody className='py-4 card'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <td>Suggestion</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody className='text-gray-600 '>
              {suggestionList.length !== 0 &&
                suggestionList.map((banner: any, index: any) => {
                  return (
                    <>
                      <tr key={index}>
                        <td style={{maxWidth: '100px'}}>
                          <span>{banner?.name}</span>
                        </td>
                        <td>
                          <button
                            className='btn btn-danger small'
                            onClick={() => deleteSuggestionById(banner.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </>
                  )
                })}
            </tbody>
          </table>
        </div>

        <div className='modal fade' tabIndex={-1} id='add_movie_model'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Add Suggestions</h5>
              </div>
              <div className='modal-body'>
                <div className='d-flex align-items-center '>
                  <div className='flex-grow-1'>
                    <input
                      placeholder='Enter Suggestions'
                      type='text'
                      name='name'
                      className={clsx('form-control form-control-solid mt-3')}
                      autoComplete='off'
                      value={formData.name}
                      onChange={(e) => setFormData({...formData, name: e.target.value})}
                    />
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    setFormData({
                      name: '',
                    })
                  }}
                >
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-dismiss='modal'
                  onClick={() => {
                    createSuggestion()
                  }}
                >
                  Add Suggestions
                </button>
              </div>
            </div>
          </div>
        </div>
      </KTCardBody>
    </>
  )
}

export default Suggestions
