import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import MovieManagement from '../modules/apps/movie-management/MovieManagement'
import Banner from '../modules/apps/movie-management/Banner'
import Suggestions from '../modules/apps/movie-management/Suggestion'
import GeneralPlugin from '../modules/apps/plugins/plugins/Componants/General/GeneralPlugin'
import StandardBanner from '../modules/apps/movie-management/StandardBanner'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='admin/auth/*' element={<Navigate to='/admin/dashboard' />} />
        {/* Pages */}
        <Route path='admin/dashboard' element={<DashboardWrapper />} />
        <Route path='admin/builder' element={<BuilderPageWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='admin/movie-management'
          element={
            <SuspensedView>
              <MovieManagement />
            </SuspensedView>
          }
        />
        <Route
          path='admin/banner'
          element={
            <SuspensedView>
              <Banner />
            </SuspensedView>
          }
        />
        <Route
          path='admin/standard-banner'
          element={
            <SuspensedView>
              <StandardBanner />
            </SuspensedView>
          }
        />
        <Route
          path='admin/suggestion'
          element={
            <SuspensedView>
              <Suggestions />
            </SuspensedView>
          }
        />

        <Route
          path='admin/plugin/*'
          element={
            <SuspensedView>
              <GeneralPlugin />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/admin/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
